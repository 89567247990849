import type { DirectiveData, DirectiveUtilities } from "alpinejs";
import Alpine from "alpinejs";

const getFileContents = (file: File) => {
  const reader = new FileReader();

  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    reader.onload = ({ target }) => {
      if (target?.result) {
        resolve(target.result);
      }
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
};

const loadFileContents = (
  file: File,
  evaluate: DirectiveUtilities["evaluate"],
  expression: DirectiveData["expression"],
) => {
  getFileContents(file)
    .then((contents) => {
      evaluate(`${expression} = __placeholder`, {
        scope: { __placeholder: contents },
      });
    })
    .catch(console.error);
};

Alpine.directive("read-file-contents-to", (el, { expression }, { cleanup, evaluate }) => {
  const handleChange = (event: Event) => {
    const target = event.target as HTMLInputElement | null;
    const file = target?.files?.[0];

    if (file) {
      loadFileContents(file, evaluate, expression);
    }
  };

  el.addEventListener("change", handleChange);

  cleanup(() => {
    el.removeEventListener("change", handleChange);
  });
});

Alpine.directive(
  "read-file-contents-via-drag-and-drop-to",
  (el, { expression }, { cleanup, evaluate }) => {
    const dragEnterHandler = () => evaluate("dragging = true");

    const dragLeaveHandler = () => evaluate("dragging = false");

    const dropHandler = (event: DragEvent) => {
      event.preventDefault();
      evaluate("dragging = false");

      if (event.dataTransfer?.items) {
        const item = [...event.dataTransfer.items][0];

        if (!item || item.kind !== "file") {
          return;
        }

        const file = item.getAsFile();

        if (!file) {
          return;
        }

        loadFileContents(file, evaluate, expression);
      } else if (event.dataTransfer?.files) {
        const file = [...event.dataTransfer.files][0];

        if (!file) {
          return;
        }

        loadFileContents(file, evaluate, expression);
      }
    };

    const dragOverHandler = (event: DragEvent) => event.preventDefault();

    el.addEventListener("dragenter", dragEnterHandler);
    el.addEventListener("dragleave", dragLeaveHandler);
    el.addEventListener("drop", dropHandler);
    el.addEventListener("dragover", dragOverHandler);

    cleanup(() => {
      el.removeEventListener("dragevent", dragEnterHandler);
      el.removeEventListener("dragleave", dragLeaveHandler);
      el.removeEventListener("drop", dropHandler);
      el.removeEventListener("dragover", dragOverHandler);
    });
  },
);
