import Alpine from "alpinejs";

// Lifted from https://stackoverflow.com/a/60815986/390966
const generateFingerprint = async (certificate: string) => {
  // Extract certificate body
  const components = certificate.match(
    /-----BEGIN CERTIFICATE-----\s*([\s\S]+?)\s*-----END CERTIFICATE-----/i,
  );

  // Components will be `null` if no matches are found
  if (!components) {
    return "";
  }

  const data = Uint8Array.from(atob(components[1]), (c) => c.charCodeAt(0));

  const digest = await crypto.subtle.digest("SHA-256", data);

  const hex = [...new Uint8Array(digest)].map((b) => b.toString(16).padStart(2, "0")).join("");

  // Return SHA256 hash in uppercase with colons
  return hex.toUpperCase().replace(/(.{2})(?!$)/g, "$1:");
};

Alpine.directive(
  "generate-certificate-fingerprint-from",
  (el, { expression }, { effect, evaluateLater }) => {
    const getCertificateValue = evaluateLater<string>(expression);

    effect(() => {
      getCertificateValue((val) => {
        generateFingerprint(val)
          .then((fingerprint) => {
            (el as HTMLInputElement).value = fingerprint;
          })
          .catch(console.error);
      });
    });
  },
);
